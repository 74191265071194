<template>
  <v-card flat class="">
    <v-data-table dense :headers="table.headers" :items="table.items" :options.sync="tableOptions"
      :server-items-length="table.totalItems" :loading="table.loading" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title><b>Daftar Pelatihan</b></v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="table.search" @change="getDataFromApi()" :prepend-inner-icon="icons.mdiMagnify"
            label="Cari dengan nama" single-line hide-details></v-text-field>

          <!-- dialog pelatihan -->
          <v-dialog v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn x-small color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="add()">
                Tambah Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-img class="mb-2" v-if="form.editedIndex > -1" max-width="500"
                  :src="form.editedItem.gambar_url"></v-img>

                <input type="file" label="Pilih Foto" accept="image/png, image/jpeg" ref="foto" @change="onFileChange()"
                  class="mb-2" />
                <v-text-field class="mb-2" v-model="form.editedItem.nama" outlined dense hide-details=""
                  label="Nama :"></v-text-field>
                <v-textarea class="mb-2" v-model="form.editedItem.deskripsi" outlined dense hide-details=""
                  label="Deskripsi :"></v-textarea>
                <v-textarea class="mb-2" v-model="form.editedItem.detail_stakeholder" outlined dense hide-details=""
                  label="Detail Stakeholder :"></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="save()"> Simpan </v-btn>
                <v-btn color="error" @click="nonAktifkan()"> Non Aktifkan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.gambar="{ item }">
        <v-img max-height="100" max-width="150" :src="item.gambar_url"></v-img>
      </template>
      <template v-slot:item.is_aktif="{ item }">
        <v-chip x-small color="error" v-if="item.is_aktif == '0'">Non Aktif</v-chip>
        <v-chip x-small color="success" v-else>Aktif</v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <span v-if="item.is_aktif == 1">
          <v-btn title="Lihat" x-small color="primary" @click="editData(item)">
            <v-icon small>
              {{ icons.mdiEye }}
            </v-icon>
            Lihat
          </v-btn>
          <router-link :to="{ name: 'AdminPelatihanDetail', params: { id: item.id } }">
            <v-btn title="Lihat" x-small color="success" class="ml-2"> Detail </v-btn>
          </router-link>

        </span>
      </template>
      <template v-slot:no-data>
        <v-btn x-small color="primary" @click="getDataFromApi()"> Reload </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'
import { mdiEye, mdiPencil, mdiMagnify } from '@mdi/js'

const apiRoot = process.env.VUE_APP_APIROOT
export default {
  components: {},
  data: () => ({
    icons: {
      mdiEye,
      mdiPencil,
      mdiMagnify,
    },
    tableOptions: {},
    table: {
      loading: true,
      headers: [
        { text: 'No', align: 'start', sortable: false, value: 'no' },
        { text: 'Gambar', value: 'gambar', sortable: false },
        { text: 'Nama', value: 'nama', sortable: false },
        { text: 'Status', value: 'is_aktif', sortable: false },
        { text: 'Aksi', value: 'actions', sortable: false },
      ],
      items: [],
      totalItems: 0,
      search: '',
    },
    dialog: false,
    form: {
      gambar_temp: '',
      editedIndex: -1,
      editedItem: {
        nama: '',
        deskripsi: '',
        detail_stakeholder: '',
        gambar: '',
        gambar_url: '',
      },
      defaultItem: {
        nama: '',
        deskripsi: '',
        detail_stakeholder: '',
        gambar: '',
        gambar_url: '',
      },
    },
  }),
  watch: {
    tableOptions: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  computed: {
    formTitle() {
      return this.form.editedIndex === -1 ? 'Tambah Data' : 'Lihat/Edit Data'
    },
    userSelected() {
      return this.$store.getters.userSelected
    },
  },
  methods: {
    onFileChange() {
      this.form.gambar_temp = this.$refs.foto.files.item(0)
      // this.form.editedItem.previewImage = URL.createObjectURL(this.form.editedItem.foto)
    },
    save() {
      if (this.form.editedIndex > -1) {
        const fmData = new FormData()
        fmData.append('aksi', 'update')
        fmData.append('id', this.form.editedItem.id)
        if (this.form.gambar_temp != undefined) {
          fmData.append('gambar', this.form.gambar_temp)
        }
        fmData.append('nama', this.form.editedItem.nama)
        fmData.append('deskripsi', this.form.editedItem.deskripsi)
        fmData.append('detail_stakeholder', this.form.editedItem.detail_stakeholder)
        axiosPostAuth('api/Pelatihan/addPelatihan', fmData)
          .then(response => {
            if (response.code === 200) {
              alert(response.message)
              this.dialog = false
              this.getDataFromApi()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        const fmData = new FormData()
        fmData.append('aksi', 'add')
        fmData.append('gambar', this.form.gambar_temp)
        fmData.append('nama', this.form.editedItem.nama)
        fmData.append('deskripsi', this.form.editedItem.deskripsi)
        fmData.append('detail_stakeholder', this.form.editedItem.detail_stakeholder)
        axiosPostAuth('api/Pelatihan/addPelatihan', fmData)
          .then(response => {
            if (response.code === 200) {
              this.getDataFromApi()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    nonAktifkan() {
      if (confirm('Apakah pelatihan sudah selesai?')) {
        const fmData = new FormData()
        fmData.append('id', this.form.editedItem.id)
        axiosPostAuth('api/Pelatihan/nonAktifkanPelatihan', fmData)
          .then(response => {
            if (response.code === 200) {
              this.dialog = false
              this.getDataFromApi()
            } else {
              alert(response.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      }
    },
    getDataFromApi() {
      const { page, itemsPerPage } = this.tableOptions
      const fmData = new FormData()
      fmData.append('page', page)
      fmData.append('itemsPerPage', itemsPerPage)
      fmData.append('search', this.table.search)
      axiosPostAuth('api/Pelatihan/getPelatihan_datatable', fmData)
        .then(response => {
          this.table.items = response.data
          this.table.totalItems = response.total
          this.table.loading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    add() {
      this.form.editedItem = Object.assign({}, this.form.defaultItem)
      this.form.editedIndex = -1
    },
    editData(item) {
      this.form.editedIndex = this.table.items.indexOf(item)
      this.form.editedItem = Object.assign({}, item)
      this.$store.commit('setUserSelected', item)
      this.dialog = true
    },
  },
}
</script>