var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.table.headers,"items":_vm.table.items,"options":_vm.tableOptions,"server-items-length":_vm.table.totalItems,"loading":_vm.table.loading},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('b',[_vm._v("Daftar Pelatihan")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.icons.mdiMagnify,"label":"Cari dengan nama","single-line":"","hide-details":""},on:{"change":function($event){return _vm.getDataFromApi()}},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-dialog',{attrs:{"max-width":"800px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"x-small":"","color":"primary","dark":""},on:{"click":function($event){return _vm.add()}}},'v-btn',attrs,false),on),[_vm._v(" Tambah Item ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[(_vm.form.editedIndex > -1)?_c('v-img',{staticClass:"mb-2",attrs:{"max-width":"500","src":_vm.form.editedItem.gambar_url}}):_vm._e(),_c('input',{ref:"foto",staticClass:"mb-2",attrs:{"type":"file","label":"Pilih Foto","accept":"image/png, image/jpeg"},on:{"change":function($event){return _vm.onFileChange()}}}),_c('v-text-field',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"Nama :"},model:{value:(_vm.form.editedItem.nama),callback:function ($$v) {_vm.$set(_vm.form.editedItem, "nama", $$v)},expression:"form.editedItem.nama"}}),_c('v-textarea',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"Deskripsi :"},model:{value:(_vm.form.editedItem.deskripsi),callback:function ($$v) {_vm.$set(_vm.form.editedItem, "deskripsi", $$v)},expression:"form.editedItem.deskripsi"}}),_c('v-textarea',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","hide-details":"","label":"Detail Stakeholder :"},model:{value:(_vm.form.editedItem.detail_stakeholder),callback:function ($$v) {_vm.$set(_vm.form.editedItem, "detail_stakeholder", $$v)},expression:"form.editedItem.detail_stakeholder"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Simpan ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.nonAktifkan()}}},[_vm._v(" Non Aktifkan ")])],1)],1)],1)],1)]},proxy:true},{key:"item.gambar",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"max-height":"100","max-width":"150","src":item.gambar_url}})]}},{key:"item.is_aktif",fn:function(ref){
var item = ref.item;
return [(item.is_aktif == '0')?_c('v-chip',{attrs:{"x-small":"","color":"error"}},[_vm._v("Non Aktif")]):_c('v-chip',{attrs:{"x-small":"","color":"success"}},[_vm._v("Aktif")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.is_aktif == 1)?_c('span',[_c('v-btn',{attrs:{"title":"Lihat","x-small":"","color":"primary"},on:{"click":function($event){return _vm.editData(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]),_vm._v(" Lihat ")],1),_c('router-link',{attrs:{"to":{ name: 'AdminPelatihanDetail', params: { id: item.id } }}},[_c('v-btn',{staticClass:"ml-2",attrs:{"title":"Lihat","x-small":"","color":"success"}},[_vm._v(" Detail ")])],1)],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.getDataFromApi()}}},[_vm._v(" Reload ")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }